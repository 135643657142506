/**
 * Created by Andrey Popov on 27.03.2023
 */

var BlocksTutorial = function (level, savedTutorial) {
    this.step = savedTutorial && savedTutorial.step || 0;
    this.config = BlocksTutorial.TutorialConfig(level);
};

BlocksTutorial.prototype.startTutorial = function () {
    if (cleverapps.config.wysiwygMode || !this.hasStep()) {
        return;
    }

    this.running = true;
    this.step = (this.step || 0) - 1;
    this.wantNextStep({
        silent: true
    });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.TUTORIAL_BEGIN);
};

BlocksTutorial.isAvailable = function (level) {
    if (cleverapps.config.editorMode) {
        return false;
    }

    return BlocksTutorial.TUTORIAL_LEVELS.filter(function (available) {
        return level.episodeNo === available.episode && level.levelNo === available.level;
    }).length > 0;
};

BlocksTutorial.TutorialConfig = function (level) {
    return BlocksTutorial.TUTORIAL_LEVELS.filter(function (available) {
        return level.episodeNo === available.episode && level.levelNo === available.level;
    })[0];
};

BlocksTutorial.prototype.isActive = function () {
    return this.running && this.hasStep();
};

BlocksTutorial.prototype.afterResize = function () {
    if (this.isActive() && !Game.currentGame.pieces.isEmpty()) {
        this.showHint();
    }
};

BlocksTutorial.prototype.wantNextStep = function (options) {
    options = options || {};

    this.step++;
    var stepData = this.config.tutorial[this.step];
    var oldStepData = this.step && this.config.tutorial[this.step - 1];

    if (oldStepData && oldStepData.force && cleverapps.forces.isRunningForce(oldStepData.force)) {
        cleverapps.forces.closeRunningForce();
    }

    if (stepData) {
        var params = stepData.grid || {};
        if (options.silent) {
            params.silent = true;
        }

        if (stepData.customGrid) {
            Game.currentGame.counter.setTimeout(function () {
                Game.currentGame.customGridForPiece(stepData.piece, options);
            }, this.step ? Grid.EXPLODE_DURATION : 0);
        }

        if (stepData.piece) {
            Game.currentGame.pieces.nextPieces = [undefined, { form: stepData.piece, color: cleverapps.Random.choose(Object.keys(BlocksGame.COLORS)) }, undefined];
        }
    } else {
        this.onFinishCallback && this.onFinishCallback();
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.GENERAL.TUTORIAL_COMPLETE);
    }
};

BlocksTutorial.prototype.needFillGrid = function () {
    var step = this.config.tutorial[this.step];
    return step && step.customGrid;
};

BlocksTutorial.prototype.showForce = function (f) {
    var stepData = this.config.tutorial[this.step];
    if (stepData.force) {
        var scene = cleverapps.scenes.getRunningScene();
        cleverapps.forces.create(scene.field, stepData.force);
        cleverapps.forces.onceForceClosed = f;
    }
};

BlocksTutorial.prototype.showHint = function () {
    if (!this.isActive()) {
        return;
    }

    var stepData = this.config.tutorial[this.step];
    var pos = this.guessPosition(stepData.piece);

    Game.currentGame.pieces.startHint({
        x: pos.x + stepData.piece[0].length / 2,
        y: pos.y + stepData.piece.length / 2
    });
};

BlocksTutorial.prototype.isCorrectPlace = function (piece, x, y) {
    var pos = this.guessPosition(piece);
    return pos.x === x && pos.y === y;
};

BlocksTutorial.prototype.guessPosition = function (piece) {
    return this.config.tutorial[this.step].position || Game.currentGame.guessPosition(piece);
};

BlocksTutorial.prototype.hasStep = function () {
    return this.step < this.config.tutorial.length;
};

BlocksTutorial.prototype.getInfo = function () {
    return {
        step: this.step
    };
};

BlocksTutorial.TUTORIAL_LEVELS = [
    {
        episode: 0,
        level: 0,
        tutorial: [
            {
                force: {
                    text: "Blocks.TutorialMessage1",
                    position: Dialogue.POSITIONS.TOP_LOWER,
                    finger: {
                        delay: 10
                    }
                },
                piece: [
                    "x",
                    "x"
                ],
                customGrid: true
            }, {
                piece: [
                    "xxx"
                ],
                customGrid: true
            }, {
                piece: [
                    ".xx",
                    "xx."
                ],
                customGrid: true
            }
        ]
    },
    {
        episode: 0,
        level: 1,
        tutorial: [
            {
                force: {
                    text: "Blocks.TutorialMessage2",
                    position: Dialogue.POSITIONS.TOP_LOWER,
                    finger: {
                        delay: 10
                    }
                },
                piece: [
                    "x",
                    "x"
                ],
                position: {
                    x: 4,
                    y: 3
                }
            }
        ]
    },
    {
        episode: 2,
        level: 0,
        tutorial: [
            {
                force: {
                    text: "Blocks.TutorialMessage.Ice",
                    position: Dialogue.POSITIONS.BOTTOM,
                    finger: {
                        delay: 10
                    }
                },
                piece: [
                    "x"
                ],
                position: {
                    x: 4,
                    y: 5
                }
            },
            {
                piece: [
                    "xx"
                ],
                position: {
                    x: 2,
                    y: 4
                }
            }
        ]
    }
];